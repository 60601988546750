import { useEffect, useRef, useState } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer'
import { env } from '~/env.ts'
import { Job } from '~/graphql/hooks/useReviewQuery'
import {
  setDefaultViewerSettings,
  addAnnotationTooltips,
  loadTextCompare
} from '~/utilities/webviewer'
import { useUser } from '~/graphql/hooks/useUser'
import { useJobManifestQuery, JobManifest } from '~/graphql/hooks/useJobManifestQuery'
import { headerCustomizations } from '~/utilities/webviewer/customizations'
import { useVariableValue } from '@devcycle/react-client-sdk'
import mbueIDB from '~/lib/mbueIDB/db'
import { useLocalStorage } from '@uidotdev/usehooks'
import Loading from '../ui/Loading'
import { usePageScrollMapQuery } from '~/graphql/hooks/usePageScrollMap'
import { buildFullPageMap } from '~/utilities/webviewer/buildFullPageMap'

const mapManifestToPageLabels = (manifest: JobManifest): string[] => {
  const pageLabels = manifest.matches.map((pageMatch) => {
    return pageMatch.revisedSheetNumber ?? (pageMatch.revisedPageIndex + 1).toString()
  })
  return pageLabels
}

const clearCache = async (): Promise<void> => {
  if (!(await mbueIDB.exists())) return

  let db: Awaited<ReturnType<typeof mbueIDB.new>> | null = null
  try {
    db = await mbueIDB.new()
    await Promise.all([db.contentLengthStore.deleteAll(), db.pdfChunkStore.deleteAll()])
  } catch (error) {
    console.error('Failed to clear PDF cache:', error)
    throw error
  } finally {
    db?.close()
  }
}

const getDocumentUri = (review: Job, isSmartOverlayEnabled: boolean) => {
  if (review.jobType === 'drawing') {
    return isSmartOverlayEnabled ? review.downloadUrls.overlay : review.diffUri
  }
  return review.originalUri
}

export const getDocument = async (url: string): Promise<string | Blob> => {
  try {
    const s3docResponse = await fetch(url, {
      headers: {
        range: 'bytes=1-200'
      }
    })

    const s3resHeaders: Headers = s3docResponse.headers
    const s3DocLastModified: string | null = s3resHeaders.get('last-modified')

    const urlKey = url.split('?')[0]
    const db = await mbueIDB.new()

    const [contentLengthData, pdfChunkData] = await Promise.all([
      db.contentLengthStore.find(urlKey),
      db.pdfChunkStore.findAllByUrl(urlKey)
    ])

    db.close()

    if (!contentLengthData.data?.contentLength || !pdfChunkData.data?.length) {
      return url
    }

    if (
      new Date(s3DocLastModified ?? Date.now()).getTime() >
      new Date(contentLengthData.data.createdAt).getTime()
    ) {
      await clearCache()
      console.log('SW: Clearing cache due to outdated content length')
      return url
    }

    const chunks = removeDuplicatesAndMaintainLargestSequence(pdfChunkData.data)

    const totalChunksLength = chunks.reduce(
      (acc, chunk) => acc + chunk.data.byteLength,
      0
    )
    const contentLength = contentLengthData.data.contentLength

    if (contentLength === totalChunksLength) {
      const arrayBuffer = chunks.map((chunk) => chunk.data)
      return new Blob(arrayBuffer, { type: 'application/pdf' })
    }

    return url
  } catch (error) {
    console.error('Error assembling PDF from chunks:', error)
    return url
  }
}

interface PdfChunk {
  rangeStart: number
  rangeEnd: number
  data: ArrayBuffer
}

function removeDuplicatesAndMaintainLargestSequence(chunks: PdfChunk[]): PdfChunk[] {
  const result = []
  let previousRangeEnd = null

  for (let i = 0; i < chunks.length; i++) {
    const current = chunks[i]
    const { rangeStart, rangeEnd } = current

    if (previousRangeEnd === null || rangeStart === previousRangeEnd + 1) {
      // Add the chunk if it follows the sequence
      result.push(current)
      previousRangeEnd = rangeEnd
    } else if (rangeStart > previousRangeEnd + 1) {
      // If out of sequence, add this chunk and reset sequence
      result.push(current)
      previousRangeEnd = rangeEnd
    } else {
      // Check if there is already a conflicting chunk in the result
      const lastAdded = result[result.length - 1]
      if (lastAdded.rangeStart === rangeStart) {
        // Replace with the larger range chunk
        const currentRange = rangeEnd - rangeStart
        const lastRange = lastAdded.rangeEnd - lastAdded.rangeStart

        if (currentRange > lastRange) {
          result[result.length - 1] = current
          previousRangeEnd = current.rangeEnd
        }
      }
    }
  }

  return result
}

const WebviewerComponent = ({ review }: { review: Job }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSmartOverlayEnabled] = useLocalStorage('smart-overlay-enabled', false)
  const isIndexedDBCacheEnabled = useVariableValue('indexed-db-cache-enabled', false)

  const { user } = useUser()
  const { projectId, reviewId } = useParams<{ projectId: string; reviewId: string }>()
  const [searchParams] = useSearchParams()
  const viewerDivRef = useRef<HTMLDivElement>(null)
  const isViewerInitialized = useRef<boolean>(false)

  const { jobManifest, loading: loadingJobManifest } = useJobManifestQuery(
    parseInt(projectId!),
    parseInt(reviewId!)
  )

  const { pageScrollMap, loading: loadingPageScrollMap } = usePageScrollMapQuery(
    parseInt(reviewId!)
  )

  let scrollMapOrginalToRevised: Record<number, number | null> = {}
  let scrollMapRevisedToOrginal: Record<number, number | null> = {}

  if (pageScrollMap) {
    scrollMapOrginalToRevised = buildFullPageMap(pageScrollMap, false)
    scrollMapRevisedToOrginal = buildFullPageMap(pageScrollMap, true)
  }

  useEffect(() => {
    if (loadingJobManifest || loadingPageScrollMap) return
    if (!user || !review || !viewerDivRef.current || !jobManifest?.data) return
    let webviewerKey: string = env.VITE_WEBVIEWER_DEMO_TOKEN ?? ''
    if (env.VITE_ENV === 'prod' && !user?.isAdmin) {
      webviewerKey = env.VITE_WEBVIEWER_TOKEN ?? ''
    }

    const documentUri = getDocumentUri(review, isSmartOverlayEnabled)

    let webviewerInstance: WebViewerInstance

    const initWebviewer = async () => {
      if (isViewerInitialized.current) return
      try {
        const isDrawing = review.jobType === 'drawing'
        webviewerInstance = await WebViewer(
          {
            path: '/webviewer-137c82f0',
            licenseKey: webviewerKey,
            preloadWorker: 'pdf',
            fullAPI: true
          },
          viewerDivRef.current!
        )

        if (isDrawing) {
          void webviewerInstance.Core.documentViewer.loadDocument(documentUri, {
            extension: 'pdf'
          })
        }

        setDefaultViewerSettings(webviewerInstance)

        const pageNumber = Number(searchParams.get('pageNumber')) || 1

        if (!isDrawing) {
          const pageMappings = pageScrollMap
            ? {
                originalToRevised: scrollMapOrginalToRevised,
                revisedToOriginal: scrollMapRevisedToOrginal
              }
            : undefined

          loadTextCompare(
            webviewerInstance,
            review.diffUri,
            review.downloadUrls.diffOriginal,
            pageNumber,
            jobManifest.data,
            review.jobType,
            setIsLoading,
            pageMappings
          )
        }

        if (isDrawing) {
          webviewerInstance.Core.documentViewer.addEventListener('documentLoaded', () => {
            const { documentViewer } = webviewerInstance.Core
            const totalPages = documentViewer.getPageCount()

            if (jobManifest?.data) {
              const pageLabels = mapManifestToPageLabels(jobManifest?.data)
              webviewerInstance.UI.setPageLabels(pageLabels)
            }

            addAnnotationTooltips(webviewerInstance, 'drawing')
            const iframeDoc = webviewerInstance.UI.iframeWindow.document
            const pageNavOverlay: HTMLDivElement | null = iframeDoc.querySelector(
              '[data-element="pageNavOverlay"]'
            )

            if (pageNavOverlay) {
              pageNavOverlay.style.display = 'none'
            }

            headerCustomizations(
              webviewerInstance,
              jobManifest.data,
              review.jobType,
              totalPages
            )
            documentViewer.setCurrentPage(pageNumber, false)
            setIsLoading(false)
          })
        }
      } catch (e) {
        console.warn('There was an error initializing the webviewer.', e)
      }
    }

    // Verifies that the flag has been set
    if (isIndexedDBCacheEnabled !== null) {
      void (async () => {
        if (!isIndexedDBCacheEnabled) {
          await clearCache()
        }
        await initWebviewer()
        isViewerInitialized.current = true
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    review,
    searchParams,
    jobManifest,
    loadingJobManifest,
    loadingPageScrollMap,
    isIndexedDBCacheEnabled,
    isSmartOverlayEnabled
  ])

  return (
    <>
      {isLoading && <Loading mode="dark" />}
      <div className="h-full w-full viewer" ref={viewerDivRef} />
    </>
  )
}

export default WebviewerComponent
